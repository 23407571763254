import { Fragment } from "react";
import { Outlet, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";

import CartIcon from "../../components/cart-icon/cart-icon.component";
import CartDropdown from "../../components/cart-dropdown/cart-dropdown.component";
import ProductSearch from "../../components/product-search/product-search.component";
import { selectCategoriesMap } from "../../store/categories/category.selector";

import { FaFacebook, FaInstagram, FaArrowLeft } from "react-icons/fa";

import { selectIsCartOpen } from "../../store/cart/cart.selector";
import { selectCurrentUser } from "../../store/user/user.selector";

import { ReactComponent as EmeStrong } from "../../assets/new_logo.svg";
import { signOutStart } from "../../store/user/user.action";

import "../../routes/navigation/navigation.styles.scss";
import NavigationBanner from "../../components/navigation-banner/navigation-banner.component";

const Navigation = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const isCartOpen = useSelector(selectIsCartOpen);
  const categoriesMap = useSelector(selectCategoriesMap);

  const [style, setStyle] = useState("container2_inicial");
  const [styleSearch, setStyleSearch] = useState("container_Search");
  const [styleOverlay, setStyleOverlay] = useState("overlay");
  const [styleLinha1, setStyleLinha1] = useState("");
  const [styleLinha2, setStyleLinha2] = useState("");
  const [styleLinha3, setStyleLinha3] = useState("");

  function closeNav() {
    setStyle("container2_hidden");
    setStyleOverlay("overlay");
    setStyleLinha1("");
    setStyleLinha2("");
    setStyleLinha3("");
    document.body.style.overflow = "";
  }

  const signOutUser = () => dispatch(signOutStart());

  const handleChangeClass = (newClass) => {
    setStyleSearch(newClass);
  };

  return (
    <Fragment>
      <div className="navigation">
        <div id="container1">
          <Link className="logo-container" to="/">
            <EmeStrong className="logo" />
          </Link>
        </div>
        <div className="nav-links-container">
          <div
            className={styleSearch}
            onClick={() => {
              window.scrollTo(0, 0);
              if (styleSearch === "container_Search") {
                setStyleSearch("container_Search_Open");
                document.body.styleSearch.overflow = "hidden";
              }
            }}
          >
            <FaArrowLeft
              className="ArrowSvg"
              onClick={() => {
                window.scrollTo(0, 0);
                if (styleSearch === "container_Search_Open") {
                  setStyleSearch("container_Search");
                  document.body.styleSearch.overflow = "";
                }
              }}
            />
            <ProductSearch
              products={categoriesMap["all"]}
              onChangeClass={handleChangeClass}
            />
          </div>
          <div className={style}>
            {/* {currentUser && (currentUser.isAdmin || false) ? (
              <Link className="nav-link" onClick={closeNav} to="/admin">
                ADMIN
              </Link>
            ) : null}
            {currentUser ? (
              <Link className="nav-link" onClick={closeNav} to="/orders">
                ORDERS
              </Link>
            ) : null} */}
            <Link className="nav-link" onClick={closeNav} to="/shop">
              SHOP
            </Link>
            <Link className="nav-link" onClick={closeNav} to="/about-us">
              ABOUT US
            </Link>
            {currentUser ? (
              <span className="nav-link" onClick={signOutUser}>
                SIGN OUT
              </span>
            ) : (
              <Link className="nav-link" onClick={closeNav} to="/auth">
                SIGN IN
              </Link>
            )}
            <div className="footer_navbar">
              <ul className="social_list">
                <li>
                  <a href="https://www.facebook.com/profile.php?id=100085393163270">
                    <FaFacebook />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/eme.strong/">
                    <FaInstagram />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <CartIcon />
          <div
            className="burger"
            id="burger"
            onClick={() => {
              window.scrollTo(0, 0);
              if (
                style === "container2_hidden" ||
                style === "container2_inicial"
              ) {
                setStyle("container2_visible");
                setStyleOverlay("overlay-show");
                setStyleLinha1("line1");
                setStyleLinha2("line2");
                setStyleLinha3("line3");
                document.body.style.overflow = "hidden";
              } else {
                setStyle("container2_hidden");
                setStyleOverlay("overlay");
                setStyleLinha1("");
                setStyleLinha2("");
                setStyleLinha3("");
                document.body.style.overflow = "";
              }
            }}
          >
            <div className={styleLinha1}></div>
            <div className={styleLinha2}></div>
            <div className={styleLinha3}></div>
          </div>
        </div>
      </div>
      {isCartOpen && <CartDropdown />}
      <div className={styleOverlay}></div>
      <NavigationBanner />
      <Outlet />
    </Fragment>
  );
};

export default Navigation;
